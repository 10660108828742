import Service from '../Service'

export default class extends Service {
  constructor (salesOrderId, id) {
    const url = ['sales-order', 'orders', salesOrderId, 'payments']
    if (id) {
      url.push(id)
    }
    super(url)
  }
}
