<template>
  <transition name="fade">
    <gf-base v-if="$route.name !== 'order-list'">
      <el-col style="height: 100%">
        <el-row style="height: 100%" :gutter="20">
          <el-col :xl="20" :md="19" style="height: 100%">
            <router-view :loaded.sync="loaded" :update.sync="update" :update-child.sync="updateChild"
              :title="title"></router-view>
          </el-col>
          <el-col :xl="4" :md="5" style="display: flex; flex-direction: column; height: 100%;">
            <div style="flex-grow: 1; height: 0; overflow: auto">
              <el-card v-if="!so.status" class="mini-card mini-card--line"
                :body-style="{ display: 'flex', flexDirection: 'column', height: '100%', padding: 0 }">
                <el-row class="mini-card__header">
                  <el-col class="mini-card__header__content">
                    <span class="mini-card__header__content__title">New Order</span>
                    <span class="mini-card__header__content__subtitle">Draft</span>
                  </el-col>
                </el-row>
              </el-card>
              <el-card v-if="so.status" class="mini-card"
                :class="{ 'mini-card--line': so.status === 3 || so.status === 4 || so.status === 5 || so.status === 6 }"
                :body-style="{ display: 'flex', flexDirection: 'column', height: '100%', padding: 0 }">
                <el-row class="mini-card__header">
                  <el-col class="mini-card__header__content">
                    <span class="mini-card__header__content__title"
                      :class="{ 'mini-card__header__content__title--active': $route.name === 'order-view' && so.id == $route.params.id }"
                      @click="clickSO">Order #{{ so.so_number }}</span>
                    <span v-if="so.status === 1" class="mini-card__header__content__subtitle">Draft</span>
                    <span v-else-if="so.status === 2" class="mini-card__header__content__subtitle">Active</span>
                    <span v-else-if="so.status === 3" class="mini-card__header__content__subtitle">Finalized</span>
                    <span v-else-if="so.status === 4" class="mini-card__header__content__subtitle">Fulfilled</span>
                    <span v-else-if="so.status === 5" class="mini-card__header__content__subtitle">Void</span>
                    <span v-else-if="so.status === 6" class="mini-card__header__content__subtitle">Deleted</span>
                  </el-col>
                </el-row>
                <el-row class="mini-card__footer">
                  <el-col class="mini-card__footer__content" v-if="so.status === 1 || so.status === 2">
                    <el-button v-if="so.status === 1" size="small" type="primary"
                      class="mini-card__footer__content__button" @click="approve"><i class="el-icon-check"></i> Approve
                      Order</el-button>
                    <el-button v-else-if="so.status === 2" size="small" type="primary"
                      class="mini-card__footer__content__button" @click="finalize"><i class="el-icon-arrow-right"></i>
                      Finalized</el-button>
                  </el-col>
                </el-row>
              </el-card>
              <el-card v-if="so.status === 3 || so.status === 4" class="mini-card"
                :class="{ 'mini-card--line': so.invoiced === 2 }"
                :body-style="{ display: 'flex', flexDirection: 'column', height: '100%', padding: 0 }">
                <el-row class="mini-card__header">
                  <el-col class="mini-card__header__content">
                    <span class="mini-card__header__content__title"
                      :class="{ 'mini-card__header__content__title--active': $route.name === 'invoice-list' }"
                      @click="clickSI">Invoices</span>
                    <span class="mini-card__header__content__subtitle mini-card__header__content__subtitle--dark"
                      :class="{ 'mini-card__header__content__subtitle--active': $route.name === 'invoice-list' }"
                      v-if="so.invoiced === 0 || so.invoiced === 1">{{ $NumberFormat(so.total - invoices.map(inv =>
                        parseFloat(inv.total)).reduce((o, n) => o + n, 0)) }} to invoice</span>
                    <span class="mini-card__header__content__subtitle mini-card__header__content__subtitle--dark"
                      :class="{ 'mini-card__header__content__subtitle--active': $route.name === 'invoice-list' }"
                      v-else>Invoiced</span>
                  </el-col>
                </el-row>
                <el-row class="mini-card__body">
                  <el-col class="mini-card__body__content" v-if="invoices.length === 0">
                    <div class="item">
                      <div class="item__icon"><i class="el-icon-plus"></i></div>
                      <div class="item__content">Create a manual invoice with any or all of the items in this order.</div>
                    </div>
                  </el-col>
                  <el-col class="mini-card__body__content" v-else>
                    <el-form label-width="130px" size="small" ref="payment" :model="payment" :rules="paymentRules">
                      <div class="item2" v-for="inv in invoices" :key="inv.id">
                        <div class="item2__content"
                          :class="{ 'item2__content--active': ($route.name === 'invoice-view' || $route.name === 'invoice-edit') && inv.id === parseFloat($route.params.iid) }"
                          @click="clickInv(inv.id)">
                          <div class="item2__content__title">
                            <div class="item2__content__title__icon"><i class="el-icon-document"></i></div>
                            <div class="item2__content__title__text">{{ inv.si_number }} <span
                                style="font-size: 12px; color: #a4aeb3" v-if="inv.is_paid">(Paid)</span></div>
                          </div>
                          <div class="item2__content__subtitle">
                            <span>{{ $NumberFormat(inv.total) }}</span>
                          </div>
                        </div>
                        <div class="item2__button" v-if="!inv.is_paid">
                          <el-popover placement="bottom-end" width="300" trigger="click" popper-class="popper">
                            <div class="popper__content">
                              <div class="popper__content__header">Create Payment</div>
                              <div class="popper__content__body">
                                <el-form-item label="Payment Type" prop="payment_method_id">
                                  <el-select v-model="payment.payment_method_id">
                                    <el-option v-for="pm in paymentMethods" :key="pm.id" :value="pm.id"
                                      :label="pm.name"></el-option>
                                  </el-select>
                                </el-form-item>
                                <el-form-item label="Amount" prop="amount">
                                  <el-input v-model="payment.amount"></el-input>
                                </el-form-item>
                                <el-form-item label="Paid On" prop="paid_on">
                                  <el-date-picker type="date" size="small" v-model="payment.paid_on" format="dd MMM yyyy"
                                    value-format="yyyy-MM-dd" style="width: 100%;">
                                  </el-date-picker>
                                </el-form-item>
                                <el-form-item label="Reference" prop="reference">
                                  <el-input v-model="payment.reference"></el-input>
                                </el-form-item>
                              </div>
                              <div class="popper__content__footer">
                                <el-button size="small" @click="createPayment(inv)">Create Payment</el-button>
                              </div>
                            </div>
                            <el-button slot="reference" size="mini" @click="clickPay(inv)">Pay</el-button>
                          </el-popover>
                        </div>
                      </div>
                    </el-form>
                  </el-col>
                </el-row>
                <el-row class="mini-card__footer" v-if="so.invoiced !== 2">
                  <el-col class="mini-card__footer__content">
                    <el-button size="small" type="primary" class="mini-card__footer__content__button"
                      @click="createInvoice('manual')"><i class="el-icon-plus"></i> Create Invoice</el-button>
                    <!-- <el-button size="small" type="primary" class="mini-card__footer__content__button" @click="handleCreateInvoice('auto')"><i class="el-icon-d-arrow-right"></i> Invoice All</el-button> -->
                  </el-col>
                </el-row>
              </el-card>
              <el-card v-if="so.status === 3 || so.status === 4" class="mini-card"
                :class="{ 'mini-card--line': so.paid === 2 }"
                :body-style="{ display: 'flex', flexDirection: 'column', height: '100%', padding: 0 }">
                <el-row class="mini-card__header">
                  <el-col class="mini-card__header__content">
                    <span class="mini-card__header__content__title">Payments</span>
                    <span class="mini-card__header__content__subtitle mini-card__header__content__subtitle--dark"
                      v-if="so.paid === 0 || so.paid === 1">{{ $NumberFormat(so.total - payments.map(pt =>
                        parseFloat(pt.amount)).reduce((o, n) => o + n, 0)) }} to pay</span>
                    <span class="mini-card__header__content__subtitle mini-card__header__content__subtitle--dark"
                      v-else>Paid</span>
                  </el-col>
                </el-row>
                <el-row class="mini-card__body">
                  <el-col class="mini-card__body__content" v-if="payments.length === 0">
                    <div class="item" v-if="invoices.length === 0">
                      <div class="item__icon"><i class="el-icon-info"></i></div>
                      <div class="item__content">Create an invoice first before making a payment.</div>
                    </div>
                    <div class="item" v-else>
                      <div class="item__icon"><i class="el-icon-info"></i></div>
                      <div class="item__content">Make a partial or full payment for an invoice by clicking the Pay button
                        on the invoice.</div>
                    </div>
                  </el-col>
                  <el-col class="mini-card__body__content" v-else>
                    <div class="item2" v-for="pt in payments" :key="pt.id">
                      <el-popover placement="left" width="240" trigger="click" popper-class="popper2">
                        <div class="popper2__content">
                          <div class="popper2__content__header">Payment for {{ pt.si_number }}</div>
                          <div class="popper2__content__body">
                            <el-form label-width="130px" label-position="left" size="small">
                              <el-form-item label="Payment Type" prop="payment_method_id">
                                {{ paymentMethods.find(pm => pm.id === pt.payment_method_id).name }}
                              </el-form-item>
                              <el-form-item label="Paid On">
                                {{ $DateFormat(pt.paid_on) }}
                              </el-form-item>
                              <el-form-item label="Amount">
                                {{ pt.amount }}
                              </el-form-item>
                              <el-form-item label="Reference">
                                {{ pt.reference }}
                              </el-form-item>
                            </el-form>
                          </div>
                          <div class="popper__content__footer">
                            <el-button size="small" @click="deletePayment(pt)">Delete</el-button>
                          </div>
                        </div>
                        <div slot="reference" class="item2__content">
                          <div class="item2__content__title">
                            <div class="item2__content__title__icon"><i class="el-icon-document"></i></div>
                            <div class="item2__content__title__text">{{ pt.si_number }}</div>
                          </div>
                          <div class="item2__content__subtitle">
                            <span>{{ $NumberFormat(pt.amount) }}</span>
                          </div>
                        </div>
                      </el-popover>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="mini-card__footer" v-if="invoices.length !== 0">
                  <el-col class="mini-card__footer__content">
                    <el-popover placement="left" width="300" trigger="click" popper-class="popper"
                      v-if="payments.length === 0">
                      <div class="popper__content">
                        <div class="popper__content__header">Create Payment</div>
                        <div class="popper__content__body">
                          <el-form label-width="130px" size="small" ref="paymentAll" :model="paymentAll"
                            :rules="paymentAllRules">
                            <el-form-item label="Payment Type" prop="payment_method_id">
                              <el-select v-model="paymentAll.payment_method_id">
                                <el-option v-for="pm in paymentMethods" :key="pm.id" :value="pm.id"
                                  :label="pm.name"></el-option>
                              </el-select>
                            </el-form-item>
                            <el-form-item label="Paid On" prop="paid_on">
                              <el-date-picker type="date" size="small" v-model="paymentAll.paid_on" format="dd MMM yyyy"
                                value-format="yyyy-MM-dd" style="width: 100%;">
                              </el-date-picker>
                            </el-form-item>
                            <el-form-item label="Reference" prop="reference">
                              <el-input v-model="paymentAll.reference"></el-input>
                            </el-form-item>
                          </el-form>
                        </div>
                        <div class="popper__content__footer">
                          <el-button size="small" @click="createPayments">Create Payment</el-button>
                        </div>
                      </div>
                      <el-button slot="reference" size="small" type="primary"
                        class="mini-card__footer__content__button"><i class="el-icon-d-arrow-right"></i> Pay
                        All</el-button>
                    </el-popover>
                    <!-- <el-button size="small" type="primary" class="mini-card__footer__content__button" @click="handleCreateInvoice('auto')"><i class="el-icon-d-arrow-right"></i> Invoice All</el-button> -->
                  </el-col>
                </el-row>
              </el-card>
              <el-card v-if="so.status === 3 || so.status === 4" class="mini-card"
                :class="{ 'mini-card--line': so.fulfilled === 2 }"
                :body-style="{ display: 'flex', flexDirection: 'column', height: '100%', padding: 0 }">
                <el-row class="mini-card__header">
                  <el-col class="mini-card__header__content">
                    <span class="mini-card__header__content__title"
                      :class="{ 'mini-card__header__content__title--active': $route.name === 'shipment-list' }"
                      @click="shipmentList">Shipments</span>
                    <span class="mini-card__header__content__subtitle mini-card__header__content__subtitle--dark"
                      :class="{ 'mini-card__header__content__subtitle--active': $route.name === 'shipment-list' }"
                      v-if="so.fulfilled === 0 || so.fulfilled === 1">{{ $NumberFormat(so.units - fulfillments.map(fm =>
                        parseFloat(fm.quantity)).reduce((o, n) => o + n, 0)) }} to fulfil</span>
                    <span class="mini-card__header__content__subtitle mini-card__header__content__subtitle--dark"
                      :class="{ 'mini-card__header__content__subtitle--active': $route.name === 'shipment-list' }"
                      v-else>Fulfilled</span>
                  </el-col>
                </el-row>
                <el-row class="mini-card__body">
                  <el-col class="mini-card__body__content" v-if="shipments.length === 0">
                    <div class="item">
                      <div class="item__icon"><i class="el-icon-plus"></i></div>
                      <div class="item__content">Create a partial shipment or pack all of the products in this order.
                      </div>
                    </div>
                    <div class="item">
                      <div class="item__icon"><i class="el-icon-check"></i></div>
                      <div class="item__content">Once packed, fulfil the shipment to reduce stock levels and add delivery
                        details.</div>
                    </div>
                  </el-col>
                  <el-col class="mini-card__body__content" v-else>
                    <div class="item2" v-for="(sm, i) in shipments" :key="sm.id">
                      <div class="item2__content"
                        :class="{ 'item2__content--active': ($route.name === 'shipment-view' || $route.name === 'shipment-edit') && sm.id === parseFloat($route.params.sid) }"
                        @click="clickSm(sm.id)">
                        <div class="item2__content__title">
                          <div class="item2__content__title__icon"><i class="el-icon-document"></i></div>
                          <div class="item2__content__title__text">#{{ i + 1 }} Shipment <span
                              style="font-size: 12px; color: #a4aeb3" v-if="sm.is_fulfilled">(Shipped)</span></div>
                        </div>
                        <div class="item2__content__subtitle">
                          <span>{{ $NumberFormat(sm.units) }}</span>
                        </div>
                      </div>
                      <div class="item2__button" v-if="!sm.is_fulfilled">
                        <el-button slot="reference" size="mini" @click="createFulfillment(sm.id)">Ship</el-button>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="mini-card__footer" v-if="so.packed !== 2">
                  <el-col class="mini-card__footer__content">
                    <el-button size="small" type="primary" class="mini-card__footer__content__button"
                      @click="packItems"><i class="el-icon-plus"></i> Pack Items</el-button>
                    <!-- <el-button size="small" type="primary" class="mini-card__footer__content__button" @click="handleCreateInvoice('auto')"><i class="el-icon-d-arrow-right"></i> Invoice All</el-button> -->
                  </el-col>
                </el-row>
                <!-- <el-row class="mini-card__footer" v-if="so.packed === 2 && so.fulfilled !== 2">
                  <el-col class="mini-card__footer__content">
                    <el-button size="small" type="primary" class="mini-card__footer__content__button" @click="handleShipAll"><i class="el-icon-d-arrow-right"></i> Marked as Shipped</el-button>
                    <el-button size="small" type="primary" class="mini-card__footer__content__button" @click="handleCreateInvoice('auto')"><i class="el-icon-d-arrow-right"></i> Invoice All</el-button>
                  </el-col>
                </el-row> -->
              </el-card>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </gf-base>
    <router-view :loaded.sync="loaded" v-else></router-view>
  </transition>
</template>

<style lang="scss" scoped>
.popper {
  .el-form-item {
    width: 100%;
    margin-bottom: 8px;
  }

  &__content {
    padding: 10px 10px 0 10px;

    &__header {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 15px;
    }

    &__footer {
      margin-top: 15px;
      text-align: center;
    }
  }
}

.popper2 {
  .el-form-item {
    width: 100%;
    margin-bottom: 0;
    border-bottom: 1px dotted #c4ced1;
  }

  &__content {
    padding: 10px 10px 0 10px;

    &__header {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 8px;
    }

    &__footer {
      margin-top: 15px;
      text-align: center;
    }
  }
}

.mini-card {
  margin-bottom: 20px;
  border: 1px solid #c4ced1;

  &--line {
    border-top: 4px solid #409EFF;
  }

  &__header {
    padding: 15px;
    cursor: pointer;

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        &>.mini-card__header__content__title {
          color: #409EFF;
        }

        &>.mini-card__header__content__subtitle {
          color: #409EFF;
        }
      }

      &__title {
        transition: .1s all;
        font-weight: 500;
        color: #645f5f;
        font-size: 14px;

        &--active {
          color: #409EFF;
        }
      }

      &__subtitle {
        transition: .1s all;
        font-size: 13px;
        font-weight: 500;
        color: #409EFF;

        &--active {
          color: #409EFF !important;
        }

        &--dark {
          color: #645f5f;
        }
      }
    }
  }

  &__body {
    border-top: 1px solid #e2e6e8;

    &__content {
      .item {
        padding: 15px 15px 0 15px;
        display: flex;
        font-size: 14px;

        &:last-child {
          padding-bottom: 15px;
        }

        &__content {
          margin-left: 10px;
        }
      }

      .item2 {
        padding: 10px 15px;
        border-bottom: 1px solid #e2e6e8;
        display: flex;
        font-size: 14px;
        align-items: center;

        span {
          width: 100%;
        }

        $this: &;

        &:last-child {
          border-bottom: none;
        }

        &__content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-grow: 1;
          cursor: pointer;

          &:hover {
            &>.item2__content__title {
              color: #409EFF;
            }

            &>.item2__content__subtitle {
              background: #409EFF;
            }
          }

          &--active {
            &>.item2__content__title {
              color: #409EFF;
            }

            &>.item2__content__subtitle {
              background: #409EFF;
            }
          }

          &__title {
            display: flex;
            align-items: center;
            transition: .1s all;

            &__icon {
              font-size: 17px;
              margin-right: 7px;
            }
          }

          &__subtitle {
            transition: .1s all;
            font-size: 12px;
            background: #909399;
            color: #fff;
            padding: 1px 5px;
            border-radius: 3px;
          }
        }

        &__button {
          margin-left: 10px;
        }
      }
    }
  }

  &__footer {
    &__content {
      display: flex;
      flex-direction: row;

      span {
        width: 100%;
      }

      .el-button--info {
        color: #555;
      }

      &__button {
        width: 100%;
        margin: 0;
        border-radius: 0;
        border: 0px;

        &--info {
          &:hover {
            background: #ececee;
            color: #555;
          }

          &:focus {
            background: #ececee;
            color: #555;
          }
        }

        .el-icon-check,
        .el-icon-arrow-right {
          color: #409EFF;
          background-color: #fff;
          border-radius: 50%;
          padding: 2px;
          font-size: 10px;
        }
      }
    }
  }
}
</style>

<script>
import SalesOrderService from '@/services/v1/SalesOrder'
import SalesInvoiceService from '@/services/v1/SalesInvoice'
import SalesPaymentService from '@/services/v1/SalesPayment'
import SalesShipmentService from '@/services/v1/SalesShipment'
import PaymentMethodService from '@/services/v1/PaymentMethod'

export default {
  data() {
    return {
      so: {
        id: null,
        so_number: null,
        status: null,
        total: null,
        invoiced: null
      },
      si: {
        si_number: null
      },
      payment: {
        sales_order_id: null,
        sales_invoice_id: null,
        si_number: null,
        payment_method_id: null,
        amount: null,
        paid_on: this.$DateISOFormat(new Date().toISOString()),
        reference: null
      },
      paymentAll: {
        sales_order_id: null,
        sales_invoice_id: null,
        si_number: null,
        payment_method_id: null,
        amount: null,
        paid_on: this.$DateISOFormat(new Date().toISOString()),
        reference: null
      },
      invoices: [],
      payments: [],
      shipments: [],
      fulfillments: [],
      paymentMethods: [],
      update: false,
      title: null,
      loaded: false,
      visiblePopover: false,

      // updates
      updateChild: false,

      // rules
      paymentAllRules: {
        payment_method_id: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        paid_on: [
          { required: true, message: ' ', trigger: 'change' }
        ]
      },
      paymentRules: {
        payment_method_id: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        paid_on: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        amount: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },

      // services
      soService: null,
      pmService: null
    }
  },
  watch: {
    '$route.name': function (after, before) {
      if (before === 'order-list') this.loaded = false
      this.pageLoad()
    },
    update: function () {
      if (this.update) {
        this.update = false
        this.loaded = false
        this.pageLoad()
      }
    }
  },
  methods: {
    async pageLoad() {
      if (this.$route.name === 'order-list') {
        return
      }

      if (this.$route.name === 'order-new') {
        this.so.so_number = null
        this.so.invoiced = 0
        this.so.status = null
        this.invoices = []
        return
      }

      // load sales order
      if (this.loaded === false) {
        await this.getSalesOrder()
        this.loaded = true
      }

      if (this.$route.name === 'order-view') {
        this.title = this.so.so_number
        return
      }

      if (this.$route.name === 'invoice-list') {
        this.title = 'Invoices'
        return
      }

      if (this.$route.name === 'invoice-new') {
        this.title = 'New Invoice'
        return
      }

      if (this.$route.name === 'shipment-list') {
        this.title = 'Shipments'
        return
      }

      if (this.$route.name === 'shipment-view' || this.$route.name === 'shipment-edit') {
        for (let i = 0; i < this.shipments.length; i++) {
          const sm = this.shipments[i]
          if (sm.id === parseInt(this.$route.params.sid)) {
            this.title = 'Shipment #' + (i + 1)
            break
          }
        }
      }
    },

    // Sales Orders
    clickSO() {
      if (this.$route.name !== 'order-view') this.$router.push({ name: 'order-view', params: { id: this.$route.params.id } })
    },
    async approve() {
      try {
        const soService = new SalesOrderService(this.$route.params.id)
        await soService.approve()
        this.$message.success(`${this.so.so_number} was successfully approved`)
        this.update = true
        this.updateChild = true
      } catch (error) {
        this.$Error(error)
      }
    },
    async finalize() {
      try {
        const soService = new SalesOrderService(this.$route.params.id)
        await soService.finalize()
        this.$message.success(`${this.so.so_number} was successfully finalized`)
        this.update = true
        this.updateChild = true
      } catch (error) {
        this.$Error(error)
      }
    },

    // invoices
    clickSI() {
      if (this.$route.name !== 'invoice-list') this.$router.push({ name: 'invoice-list', params: { id: this.$route.params.id } })
    },
    clickInv(id) {
      if (this.$route.name === 'invoice-view' && id === parseInt(this.$route.params.iid)) {
        return
      }

      this.$router.push({ name: 'invoice-view', params: { id: this.$route.params.id, iid: id } })
    },
    createInvoice(type) {
      if (type === 'manual') {
        if (this.$route.name !== 'invoice-new') this.$router.push({ name: 'invoice-new' })
      }
    },

    // payments
    clickPay(inv) {
      this.payment.amount = parseFloat(inv.total) - parseFloat(inv.paid)
    },
    createPayments() {
      this.$refs.paymentAll.validate()
        .then(async () => {
          const invoices = this.$_.cloneDeep(this.invoices)
          for (let i = 0; i < invoices.length; i++) {
            try {
              const paymentAll = this.$_.cloneDeep(this.paymentAll)
              const inv = invoices[i]
              paymentAll.sales_order_id = parseInt(this.$route.params.id)
              paymentAll.sales_invoice_id = inv.id
              paymentAll.si_number = inv.si_number
              paymentAll.amount = inv.total

              this.$Sanitize(paymentAll)

              const spService = new SalesPaymentService(this.$route.params.id)
              await spService.create(paymentAll)
              this.$message.success(`${inv.si_number} was successfully paid`)
            } catch (error) {
              this.$Error(error)
            }
          }

          this.update = true
          this.updateChild = true
        })
        .catch(() => { })
    },
    createPayment(inv) {
      this.$refs.payment.validate()
        .then(async () => {
          try {
            const payment = this.$_.cloneDeep(this.payment)
            payment.sales_order_id = parseInt(this.$route.params.id)
            payment.sales_invoice_id = inv.id
            payment.si_number = inv.si_number

            this.$Sanitize(payment)

            const spService = new SalesPaymentService(this.$route.params.id)
            await spService.create(payment)
            this.$message.success(`${inv.si_number} was successfully paid`)

            this.update = true
            this.updateChild = true
          } catch (error) {
            this.$Error(error)
          }
        })
        .catch(() => { })
    },
    async deletePayment(pt) {
      try {
        const spService = new SalesPaymentService(this.$route.params.id, pt.id)
        await spService.del()
        this.$message.success(`${pt.si_number} payment was successfully deleted`)
        this.update = true
        this.updateChild = true
      } catch (error) {
        console.log(error)
        this.$Error(error)
      }
    },

    // shipments
    packItems() {
      if (this.$route.name !== 'shipment-new') this.$router.push({ name: 'shipment-new' })
    },
    shipmentList() {
      if (this.$route.name !== 'shipment-list') this.$router.push({ name: 'shipment-list', params: { id: this.$route.params.id } })
    },
    clickSm(id) {
      if (this.$route.name === 'shipment-view' && id === parseInt(this.$route.params.sid)) {
        return
      }

      for (let i = 0; i < this.shipments.length; i++) {
        const sm = this.shipments[i]
        if (sm.id === id) {
          this.title = 'Shipment #' + (i + 1)
          break
        }
      }

      this.$router.push({ name: 'shipment-view', params: { id: this.$route.params.id, sid: id } })
    },

    // Fulfillment
    async createFulfillment(id) {
      try {
        const ssService = new SalesShipmentService(this.$route.params.id, id)
        await ssService.createFulfillment()
        this.$message.success('Fulfillment was successfully created')
        this.update = true
        this.updateChild = true
      } catch (error) {
        this.$Error(error)
      }
    },

    // Service Fetch
    async getSalesOrder() {
      try {
        this.title = null
        this.invoices = []
        this.payments = []
        this.shipments = []
        const soService = new SalesOrderService(this.$route.params.id)
        const result = await soService.getSOandStatus()
        this.so = result.data

        if (this.so.invoiced !== 0) {
          await this.getSalesInvoices()
        }

        if (this.si.paid !== 0) {
          await this.getPayments()
        }

        if (this.so.packed !== 0) {
          await this.getShipments()
          await this.getFulfillments()
        }
      } catch (error) {
        this.$Error(error)
      }
    },
    async getSalesInvoices() {
      const siService = new SalesInvoiceService(this.$route.params.id)
      const result = await siService.getSIandStatus()
      this.invoices = result.data.rows
    },
    async getPaymentMethods() {
      try {
        const pmService = new PaymentMethodService()
        const result = await pmService.list()
        this.paymentMethods = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },
    async getPayments() {
      const spService = new SalesPaymentService(this.$route.params.id)
      const result = await spService.list()
      this.payments = result.data.rows
    },
    async getShipments() {
      const ssService = new SalesShipmentService(this.$route.params.id)
      const result = await ssService.getStatus()
      this.shipments = result.data.rows
    },
    async getFulfillments() {
      const ssService = new SalesShipmentService(this.$route.params.id)
      const result = await ssService.getFulfillmentStatus()
      this.fulfillments = result.data.rows
    }
  },
  mounted() {
    this.pageLoad()
    this.getPaymentMethods()
  }
}
</script>
